<template>
  <div>
    <LoadingSpinner :isLoading="isLoading" />
    <div v-if="user.role == 'admin' || user.role == 'seller'" class="bg-primary-50 px-4 py-5 flex flex-col">
      <div class="clearfix border-b-2 border-secondary-500 pb-2 mb-3">
        <div class="float-left text-lg text-bold font-serif">
          Välj Larm
        </div>
      </div>
      <ValidationObserver ref="formAlarmSearch">
        <div class="flex flex-wrap">
          <div class="w-full lg:w-1/3 px-2 py-2">
            <BaseSelect
              v-model.trim="customer"
              :options="customers"
              optionSelector="_id"
              nameSelector="name"
              field_name="Kund"
              @change="
                filterUnitByCustomer();
                unit = '';
              "
            />
          </div>
          <div class="w-full lg:w-1/3 px-2 py-2">
            <BaseSelect v-model.trim="unit" :options="customer_units" optionSelector="unit_id" nameSelector="name" field_name="Verksamhet" />
          </div>
          <div class="w-full lg:w-1/3 px-2 py-2">
            <BaseInput v-model.trim="phone" type="text" field_name="Telematiknummer" />
          </div>
          <div class="w-full lg:w-1/3 px-2 py-2">
            <BaseInput v-model.trim="alarm_name" type="text" field_name="Namn" />
          </div>
          <div class="w-full lg:w-1/3 px-2 py-2">
            <BaseInput v-model.trim="modelnumber" type="text" field_name="Serienummer" />
          </div>
          <div class="w-full lg:w-1/3 px-2 py-2">
            <BaseInput v-model.trim="imei_number" type="text" field_name="IMEI" />
          </div>
        </div>
        <div class="clearfix border-t border-secondary-500 pt-3 mt-3">
          <button class="float-left btn-secondary-outline text-lg px-5 py-1" @click="resetSearch()">
            Återställ
            <BaseIcon icon="undo" class="ml-1" />
          </button>
          <button class="float-right btn-blue text-lg px-5 py-1" @click="searchAlarms()">
            Sök
            <BaseIcon icon="search" class="ml-1" />
          </button>
        </div>
      </ValidationObserver>
    </div>
    <div v-else class="border-b border-secondary-300 mb-2">
      <ValidationObserver ref="formAlarmSearch">
        <div class="flex flex-wrap">
          <div class="w-full lg:w-4/12 lg:pr-4 py-2">
            <ValidationProvider name="IMEI/Telematiknummer/Serienummer" rules="required" v-slot="{ classes, errors }">
              <div class="input-validate" :class="classes">
                <input type="text" v-model.trim="searchQuery" placeholder="IMEI/Telematiknummer/Serienummer" v-on:keyup.enter="searchAlarms" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2" />
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="w-full lg:w-4/12 py-2">
            <button class="btn-blue text-sm px-5 py-2" @click="searchAlarms()">
              Sök
              <BaseIcon icon="search" class="ml-1" />
            </button>
          </div>
        </div>
      </ValidationObserver>
    </div>
    <div class="flex flex-col mt-8" v-if="showSearchTable">
      <div class="w-full">
        <vue-good-table styleClass="vgt-table striped bordered condensed" row-style-class="text-sm" :columns="columns" :rows="search_results" :search-options="{ enabled: false }" :pagination-options="{ enabled: false }">
          <div slot="emptystate" class="flex flex-wrap flex-col items-center my-5">
            <div class="text-sm mb-4">
              Inga larm hittades
            </div>
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'phone'">
              <router-link class="text-primary-400 underline hover:text-primary-600 transition-all duration-100" :to="'/alarm-report/alarm/' + props.row.imei_number">
                {{ props.row.phone }}
              </router-link>
            </span>
            <span v-else-if="props.column.field == 'customer_name'">
              {{ getCustomerName(props.row.parent_id) }}
            </span>
            <span v-else-if="props.column.field == 'lastSeenTime'">
              <span v-if="props.row.lastSeenTime == false" class="text-gray-400">⬤</span>
              <span v-else-if="props.row.lastSeenTime && new RegExp('^SRT').test(props.row.type)" class="last-seen" v-bind:class="{ inactive: props.row.lastSeenTime > 1000 * 60 * 60 }">⬤</span>
              <span v-else-if="props.row.lastSeenTime && new RegExp('^TWIG').test(props.row.type)" class="last-seen" v-bind:class="{ inactive: props.row.lastSeenTime > 1000 * 60 * 60 * 2.5 }">⬤</span>
              <span v-else-if="props.row.lastSeenTime && new RegExp('^TELTONIKA').test(props.row.type)" class="last-seen" v-bind:class="{ inactive: props.row.lastSeenTime > 1000 * 60 * 60 }">⬤</span>
            </span>
            <span v-else-if="props.column.field == 'report'">
              <router-link class="btn-blue-outline" :to="'/alarm-report/alarm/' + props.row.imei_number" target="_blank">
                Visa
                <BaseIcon icon="arrow-right" class="ml-1" />
              </router-link>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AlarmSearch",
  title() {
    return `Larmrapport | SecurCloud`;
  },
  data() {
    return {
      isLoading: false,
      showSearchTable: false,
      customers: [],
      units: [],
      customer_units: [],
      customer: "",
      unit: "",
      phone: "",
      alarm_name: "",
      modelnumber: "",
      imei_number: "",
      searchQuery: "",
      search_results: [],
      gps_alarm: [],
      columns: [
        { label: "Telematiknummer", field: "phone", sortable: false },
        { label: "Serienummer", field: "modelnumber", sortable: false },
        { label: "IMEI", field: "imei_number", sortable: false },
        { label: "Namn på Produkt", field: "unit_name", sortable: false },
        { label: "Kundsnamn", field: "customer_name", sortable: false },
        { label: "Verksamhetsnamn", field: "parent_id", sortable: false, formatFn: this.getUnitName },
        { label: "Status", field: "lastSeenTime", sortable: false },
        { label: "Rapport", field: "report", sortable: false },
      ],
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  methods: {
    async getCustomers() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/customer`);
        this.customers = response.data.data;
      } catch (error) {
        this.handleError(error);
      }
    },

    async getUnits() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/units`);
        this.units = response.data.data;
      } catch (error) {
        this.handleError(error);
      }
    },

    filterUnitByCustomer() {
      this.customer_units = _.filter(this.units, { parent_id: this.customer });
    },

    async searchAlarms() {
      if (this.customer != "" || this.unit != "" || this.phone != "" || this.alarm_name != "" || this.modelnumber != "" || this.imei_number != "" || this.searchQuery != "") {
        try {
          this.isLoading = true;
          let data = {
            customer: this.customer,
            unit: this.unit,
            phone: this.phone,
            alarm_name: this.alarm_name,
            modelnumber: this.modelnumber,
            imei_number: this.imei_number,
            searchQuery: this.searchQuery,
          };

          let response = await this.axios.post(`${process.env.VUE_APP_SERVER_URL}/alarms/search`, data);
          this.gps_alarm = response.data.gps_alarm;

          let alarms = [];
          response.data.alarms.forEach((alarm) => {
            alarms.push({
              ...alarm,
              lastSeenTime: this.getAlarmLastSeen(alarm.imei_number),
            });
          });
          this.search_results = alarms;

          this.isLoading = false;
          this.showSearchTable = true;
        } catch (error) {
          this.isLoading = false;
          this.handleError(error);
        }
      } else {
        this.handleError(null, "Vänligen ange minst en parameter");
      }
    },

    resetSearch() {
      this.customer = "";
      this.unit = "";
      this.phone = "";
      this.alarm_name = "";
      this.modelnumber = "";
      this.imei_number = "";
      this.showSearchTable = false;
      this.search_results = [];
      this.$refs.formAlarmSearch.reset();
    },

    getCustomerName(unit_id) {
      let u = _.find(this.units, { unit_id: unit_id });
      if (u) {
        let c = _.find(this.customers, { _id: u.parent_id });
        if (c) return c.name;
        else return "";
      } else return "";
    },

    getUnitName(unit_id) {
      let u = _.find(this.units, { unit_id: unit_id });
      if (u) return u.name;
      else return "";
    },

    getAlarmLastSeen(imei) {
      let a = _.find(this.gps_alarm, { imei_number: imei });
      if (a) return this.moment().diff(this.moment(a.updatedAt));
      else return false;
    },

    columnFilter() {
      this.columns = [
        { label: "Telematiknummer", field: "phone", sortable: false },
        { label: "Serienummer", field: "modelnumber", sortable: false },
        { label: "IMEI", field: "imei_number", sortable: false },
        { label: "Status", field: "lastSeenTime", sortable: false },
        { label: "Rapport", field: "report", sortable: false },
      ];
    },
  },

  created() {
    // hide customer data from tech user
    if (this.user.role == "admin" || this.user.role == "seller") {
      this.getCustomers();
      this.getUnits();
    } else this.columnFilter();

    this.setPageTitle(`Larmrapport`, "search_alarm");
  },
};
</script>
